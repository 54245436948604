.navbar .dropdown-menu .link-list-wrapper ul li a.list-item.active span {
  color: $link-color;
}

.chip {
  &.chip-primary {
    color: $primary-text;

    & > .chip-label {
      color: $primary-text;
    }

    &:hover {
      background-color: $primary;
      border-color: $primary;

      & > .chip-label {
        color: $primary-text;
      }
    }
  }
}

.btn-outline-primary {
  color: $primary-text;

  &:hover {
    color: darken($primary-text, 10%);
  }
}

.icon-primary {
  fill: $link-color !important;
}

.nav-tabs {
  .nav-link:hover {
    color: $link-color;
  }

  .nav-link.active,
  .form-control:disabled + label.nav-link,
  .form-control[readonly] + label.nav-link,
  .nav-item.show .nav-link {
    border-bottom-color: $link-color;
    color: $link-color;
  }
}

#search-modal {
  .text-primary {
    color: $link-color !important;
  }
}
